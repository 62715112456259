const hasSVG = [
  "bing",
  "carfax",
  "communityrewards",
  "elocal",
  "facebook",
  "glassdoor",
  "google",
  "googlemap",
  "indeed",
  "instagram",
  "kyruus",
  "linkedin",
  "listyourself",
  "mapquest",
  "moboom",
  "opentable",
  "placekey",
  "reviewbuilder",
  "safegraph",
  "threads",
  "trustpilot",
  "twitter",
  "uber",
  "vitals",
  "webmd",
  "yasabe",
  "yelp",
  "yelpca",
  "yelpfr",
  "yelpgb",
  "zillow",
].reduce((s, x) => ({ ...s, [x]: true }), {});

export default hasSVG;
